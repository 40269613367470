import { attractValue, imperdivelValue, impulseAdmin, languageValues, paymentMethodsValues, valuesPeopleType, valuesSegments, valuesTypeLocal } from '../InputComponents/ValuesCheckBox';

export const fieldsEditInventory = [
  {    
    title: 'Titulo*',
    field: 'Title',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    explanation: 'Coloque aqui o nome do local, com letras maiúsculas e minúsculas. Ex: Restaurante Fogão',
  },
  {    
    title: 'Tipo*',
    field: 'Type',
    boolType: false,
    component: 'select',
  },
  {    
    title: 'Subtipo*',
    field: 'Subtype',
    boolType: false,
    component: 'select',
  },
  {    
    title: 'Especialidade*',
    field: 'Especialidade',
    boolType: false,
    component: 'select',
    rankingPoints: true,
  },
  {    
    title: 'Endereço',
    field: 'Endereço',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    explanation: 'Rua e número. Ex: Rua da Flora, 10',
  },
  {    
    title: 'Geolocalização',
    field: 'location',
    boolType: false,
    component: 'location',
    placeholder: '[ Latitude, Longitude ]',
    explanation: 'Localizar no Google Maps, clicar com botão direito e colar os dados. Ex: -23.22222, -47.44444',
  },
  {    
    title: 'Ponto de Acesso',
    field: 'access',
    boolType: false,
    component: 'location',
    placeholder: '[ Latitude, Longitude ]',
    explanation: 'Em caso de não haver acesso por carro, colocar o último acesso por rua/estrada',
  },
  {    
    title: 'Cidade',
    field: 'City',
    boolType: false,
    component: 'select',
    disable: true,
  },
  {    
    title: 'Telefone',
    field: 'Telefone',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '(xx) xxxx-xxxx',
  },
  {    
    title: 'WhatsApp',
    field: 'Whatsapp',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '(xx) xxxx-xxxx',
  },
  {    
    title: 'Email',
    field: 'Email',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Email de Administrador',
    field: 'emailAdmin',
    type: 'text',
    boolType: false,
    component: 'text',
    explanation: 'Email do administrador do atrativo.',
  },
  {    
    title: 'Website',
    field: 'Website',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {    
    title: 'Vídeo (Link)',
    field: 'Video',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {    
    title: 'Facebook',
    field: 'Facebook',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {    
    title: 'Instagram',
    field: 'Instagram',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {    
    title: 'Horário de Funcionamento',
    field: 'Horário de Funcionamento',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Colocar dias e horários. Ex: Segunda a Sábado das 9h às 18h',
  },
  {    
    title: 'Meios de Pagamento',
    field: 'Meios de Pagamento',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: paymentMethodsValues,
    typeCheck: true,
  },
  {    
    title: 'Aceita Cartões',
    field: 'Aceita Cartões',
    type: 'radio',
    boolType: false,
    component: 'radio',
  },
  {    
    title: 'Aceita Animais',
    field: 'Aceita Animais',
    type: 'radio',
    boolType: false,
    component: 'radio',
  },
  {    
    title: 'Acessibilidade',
    field: 'Acessibilidade',
    type: 'radio',
    boolType: false,
    component: 'radio',
    rankingPoints: true,
  },
  {    
    title: 'Atendimento Idioma Estrangeiro',
    field: 'Atendimento Idioma Estrangeiro',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: languageValues,
    typeCheck: true,
  },
  {    
    title: 'Capacidade Individual',
    field: 'Capacidade Individual',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
    explanation: 'Número de pessoas (leitos ou pessoas sentadas)',
    rankingPoints: true,
  },
  {    
    title: 'Número do Cadastur',
    field: 'Cadastur',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    explanation: 'Incluir n° do registro',
    rankingPoints: true,
  },
  {    
    title: 'UHs',
    field: 'UHs',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
    explanation: 'Unidades Habitacionais',
    rankingPoints: true,
  },
  /* {    
    title: 'Ranking',
    field: 'Ranking',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  }, */
  {    
    title: 'Decoração diferenciada ou temática',
    field: 'Decoração diferenciada ou temática',
    type: 'radio',
    boolType: false,
    component: 'radio',
  },
  {    
    title: 'Número de Funcionários Fixos',
    field: 'Número de Funcionários Fixos',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
    rankingPoints: true,
  },
  {    
    title: 'Número de Funcionários Temporários',
    field: 'Número de Funcionários Temporários',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
    rankingPoints: true,
  },
  {    
    title: 'Segmento Turístico',
    field: 'Segmento Turístico',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: valuesSegments,
    typeCheck: true,
    rankingPoints: true,
  },
  {    
    title: 'Lista de Imagens',
    field: 'imagefile',
    type: 'file',
    boolType: false,
    component: 'image',
    explanation: 'Tamanho máximo da imagem: 1MB (MegaBytes)',
  },
  {    
    title: 'Características',
    field: 'typeLocal',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: valuesTypeLocal,
    typeCheck: false,
    rankingPoints: true,
  },
  /* {    
    title: 'Impulsionar',
    field: 'Impulsionar',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: impulseAdmin,
    typeCheck: false,
  }, */
  {    
    title: 'Faixa Etária',
    field: 'FaixaEtaria',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Valor',
    field: 'Valor',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    explanation: 'Informe o valor individual ou faixa de valor, por exemplo: De R$ 100,00 a R$ 300,00',
  },
  {    
    title: 'Tipo de Público',
    field: 'TipoPublico',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: valuesPeopleType,
    typeCheck: true,
    explanation: 'Marque apenas o tipo de público específico',
  },
  {    
    title: 'Classificar como Imperdível',
    field: 'Imperdivel',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: imperdivelValue,
    typeCheck: false,
  },
  {    
    title: 'Destaques do Local pra ele ser imperdível',
    field: 'destaqueLocal',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Descreva os produtos ou atividades de destaques do local como sugestão do chefe, pratos recomendados, especialidades, atividades diferenciadas, etc',
  },
  {    
    title: 'Classificar como Atrativo',
    field: 'classificadoAtrativo',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: attractValue,
    typeCheck: false,
  },
  {    
    title: 'Destaques do Local para ser classificado como Atrativo',
    field: 'justifiqueAtrativo',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Descreva o porque do local é visto como atrativo, motivo ao qual o empreendimento gera fluxo turístico',
  },
  {    
    title: 'Descrição Curta*',
    field: 'Short Description',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Descreva aqui o que sua empresa oferece. Ex: Restaurante com serviço self-service',
  },
  {    
    title: 'Descrição Longa*',
    field: 'Long Description',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Descreva aqui detalhes do serviço e infraestrutura',
  },
  {    
    title: 'Lista de Atividades',
    field: 'Activities List',
    type: 'text',
    boolType: false,
    component: 'adder',
    explanation: 'Descreva as atividades praticadas no local, informações da infraestrutura',
  },
  {    
    title: 'Visível*',
    field: 'Visible',
    type: 'select',
    boolType: true,
    component: 'select',
  },
  {    
    title: 'Ativo*',
    field: 'Active',
    type: 'select',
    boolType: true,
    component: 'select',
  },
];